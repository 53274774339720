import axios from 'axios'
import qs from 'qs'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' },
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
})
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (!token) {
      throw new Error('no token')
    }
    config.headers.Authorization = 'Bearer ' + token
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default instance
